<template>
	<div>
		<el-dialog title="文件预览" :visible="showDoc == true || showPdf == true  || showImg == true" :width="fileWidth" class="dialog-div-pre-style"
		 :before-close="closePreviewClick" center append-to-body>
			<div v-if="showDoc == true" class="dialog-body-content-base-style">
				<iframe frameborder="0" :src="'https://view.officeapps.live.com/op/view.aspx?src=' + this.fileUrl" width='100%'
				 height="800px">
				</iframe>
			</div>
			<div v-else-if="showPdf == true" class="dialog-body-content-base-style" style="justify-content: center; align-items: center">
				<embed :src="fileUrl" style="width: 100%; height: 80vh" />
			</div>
			<div v-else-if="showImg == true" class="dialog-body-content-base-style" style="justify-content: center; align-items: center;min-width: 40px;min-height: 40px;">
				<img :src="fileUrl" alt="" style="width: 100%;height: auto;max-height: 85vh;">
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "showFile",
		data() {
			return{
				showDoc: false,
				showPdf: false,
				showImg: false,
				fileUrl: "",
				fileWidth: '80%',
			}
		},
		methods:{
			preview(url) {
				let type = url;
				if(url.indexOf(this.alOssUrl) == -1){
					this.fileUrl = this.alOssUrl+url
				}else{
					this.fileUrl=url
				}
				if (type.indexOf("doc") != -1 || type.indexOf("docx") != -1 || type.indexOf("xls") != -1 || type.indexOf("xlsx") !=
					-1 || type.indexOf(
						"pptx") !=
					-1 || type.indexOf(
						"ppt") !=
					-1) {
					this.fileWidth = '80%'
					this.showDoc = true
				} else if (type.indexOf("pdf") != -1) {
					this.fileWidth = '80%'
					this.showPdf = true
				} else if (type.indexOf("jpg") != -1 || type.indexOf("png") != -1 || type.indexOf("jpeg") != -1) {
					this.showImg = true
					let image = new Image();
					image.src = this.alOssUrl+url;
					image.onload = () => {
						this.fileWidth = image.width + 'px'
					};
				} else {
					this.$message("当前文件暂不支持预览")
				}
			},
			closePreviewClick() {
				if (this.showDoc == true) {
					this.showDoc = false
				} else if (this.showPdf == true) {
					this.showPdf = false
				} else if (this.showImg == true) {
					this.showImg = false
				}
			}
		}
	}
</script>

<style scoped>
	.dialog-div-pre-style /deep/ .el-dialog__body {
		overflow-y: auto;
	}
</style>
