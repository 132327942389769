<template>
	<div>
		<el-dialog class="dialog_Model" v-if="Model" :title="region_theme_text + '详情'"  :visible.sync="Model" width="1000px" center>
			<el-form label-width="100px" label-position="reght" class="form-style" v-loading="loading">
				<el-form-item label="编码:" v-if="role != 'staff'">
					{{competition_course.num}}
				</el-form-item>
				<el-form-item :label="util.getKeyFrontValue('cover') + ':'">
					<img :src="alOssUrl + competition_course.cover" style="width: 100px;height: 100px;margin-top: 10px;" alt=""
						@click="showFile(competition_course.cover)">
				</el-form-item>
				<el-form-item :label="util.getKeyFrontValue('name') + ':'">
					{{competition_course.name}}
				</el-form-item>
				<el-form-item :label="util.getKeyFrontValue('category') + ':'">
					<span v-if="competition_course.relate_course_category">
						<span v-if="competition_course.relate_course_category.kind == 'labor'">劳动教育-</span>
						<span v-if="competition_course.relate_course_category.kind == 'comprehensive'">综合实践-</span>
						<span>{{ competition_course.relate_course_category.name }}</span>
					</span>
				</el-form-item>
				<el-form-item :label="util.getKeyFrontValue('apply') + ':'">
					{{competition_course.apply_to_stages && competition_course.apply_to_stages.length > 0 ? util.setStage(competition_course.apply_to_stages) : ''}}
				</el-form-item>
				<el-form-item :label="util.getKeyFrontValue('introduction') + ':'">
					<div class="description-box">
						<pre>{{competition_course.introduction}}</pre>
					</div>
				</el-form-item>
				<!-- <el-form-item :label="util.getKeyFrontValue('description') + ':'" v-if="is_permit_description && competition_course.description">
					<div v-html="competition_course.description"></div>
				</el-form-item> -->
				<el-form-item label="作者自荐:" v-if="is_permit_author_recommendation && competition_course.author_recommendation">
					<div class="description-box">
						<pre>{{competition_course.author_recommendation}}</pre>
					</div>
				</el-form-item>
				<el-form-item :label="util.getKeyFrontValue('video') + ':'" v-if="is_permit_video && competition_course_video.length > 0" >
					<div v-for="item in competition_course_video" class="video-form-item">
						<div>
							<video :src="alOssUrl+item.path" controls="controls" class="video-style"></video>
							<span class="comment-default-text-btn" @click="downloadVideoOrAudio(item)">下载</span>
						</div>
					</div>
				</el-form-item>
				<el-form-item :label="util.getKeyFrontValue('audio') + ':'" v-if="is_permit_audio && competition_course_audio.length > 0">
					<!-- <div v-for="item in competition_course_audio" class="video-form-item">
						<div>
							<audio :src="alOssUrl+item.path" controls="controls" class="audio-style"></audio>
							<span class="comment-default-text-btn" @click="downloadVideoOrAudio(item)">下载</span>
						</div>
					</div> -->
					<div class="my-upload-list">
						<div v-for="(item,index) in competition_course_audio">
							<div class="upload-flex">
								<img :src="util.setFileIcon(item.path)" class='my-file'>
								<span>{{ item.name.length>25 ? item.name.substr(0, 25)+'...':item.name }}({{item.size}})</span>
							</div>
							<div>
								<span @click='showFile(item.path)' class="show-flie-open">预览</span>
								<span @click='downloadFile(item.path, item.name)' class="show-flie-open">下载</span>
							</div>
						</div>
					</div>
				</el-form-item>
				<el-form-item :label="util.getKeyFrontValue('file') + ':'" v-if="competition_course_file.length > 0">
					<div class="my-upload-list">
						<div v-for="(item,index) in competition_course_file">
							<div class="upload-flex">
								<img :src="util.setFileIcon(item.path)" class='my-file'>
								<span>{{ item.name.length>25 ? item.name.substr(0, 25)+'...':item.name }}({{item.size}})</span>
							</div>
							<div>
								<span @click='showFile(item.path)' class="show-flie-open">预览</span>
								<span @click='downloadFile(item.path, item.name)' class="show-flie-open">下载</span>
							</div>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="姓名:">
					{{competition_course_author.name}}
				</el-form-item>
				<el-form-item label="单位类型:">
					<span v-if="competition_course_author.affiliation_type == 1">学校</span>
					<span v-else-if="competition_course_author.affiliation_type == 2">基地</span>
					<span v-else-if="competition_course_author.affiliation_type == 3">机构</span>
				</el-form-item>
				<el-form-item label="所属单位:">
					{{competition_course_author.relate_region ? competition_course_author.relate_region.municipal_name : ''}}
					{{competition_course_author.relate_region ? competition_course_author.relate_region.county_name : ''}}
					{{competition_course_author.affiliation}}
				</el-form-item>
				<!-- <el-form-item label="所属教育局:" v-if="is_permit_confirm_region_code && competition_course.relate_confirm_region">
					{{competition_course.relate_confirm_region.mergenames}}
				</el-form-item> -->
				<el-form-item label="收货地址:">
					{{competition_course_author.shipping_address}}
				</el-form-item>
				<el-form-item label="状态:" v-if="role == 'government'">
					<span v-if="listInfo.confirm_status == 8">待审核</span>
					<span v-if="listInfo.confirm_status == 9">未通过</span>
					<span v-if="listInfo.confirm_status == 10">已通过</span>
				</el-form-item>
				<el-form-item label="得分:" v-if="role != 'filter'">
						{{score}}
					<!-- <span v-if="role == 'review'">
						{{listInfo.relate_avg_competition_course_comment_belong_competition_course_expert ? listInfo.relate_avg_competition_course_comment_belong_competition_course_expert.average_rate : '暂无'}}
					</span> -->
				
					<!-- <span v-else-if="role == 'government'">
						{{listInfo.score ? listInfo.score.split('.')[0] + '.' + (listInfo.score.split('.')[1] + '').slice(0,2) : '暂无'}}
					</span> -->
				</el-form-item>
				<el-form-item style="margin-top: 40px;" label="">
					<button type="button" class="cancel-btn" @click="hide">关闭</button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<showFile ref='show'></showFile>
	</div>
</template>

<script>
	import showFile from './showFile.vue'
	export default {
		props: ['role'],
		components: {
			showFile
		},
		data() {
			return {
				region_theme_text: JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程',
				loading: false,
				Model: false,
				listInfo: {},
				// 课程基本信息
				competition_course: '',
				// 课程音频
				competition_course_audio: '',
				// 课程视频
				competition_course_video: '',
				// 作者信息
				competition_course_author: '',
				// 附件
				competition_course_file: '',
				score:"",
				
				// 是否显示教育局
				is_permit_confirm_region_code: JSON.parse(sessionStorage.getItem('region_detail')).is_permit_confirm_region_code,
				// 是否显示作者自荐
				is_permit_author_recommendation: JSON.parse(sessionStorage.getItem('region_detail')).is_permit_author_recommendation,
				// 是否显示音频视频
				is_permit_audio: JSON.parse(sessionStorage.getItem('region_detail')).is_permit_audio,
				is_permit_video: JSON.parse(sessionStorage.getItem('region_detail')).is_permit_video,
				// 是否显示案例正文
				is_permit_description: JSON.parse(sessionStorage.getItem('region_detail')).is_permit_description,
			}
		},
		methods: {
			hide() {
				this.Model = false
				this.listInfo = {},
				// 课程基本信息
				this.competition_course = '',
				// 课程音频
				this.competition_course_audio = ''
				// 课程视频
				this.competition_course_video = ''
				// 作者信息
				this.competition_course_author = ''
				// 附件
				this.competition_course_file = ''
			},
			show(row) {
				this.listInfo = row
				this.Model = true
				this.loading=true;
				if(this.role == 'review') {
					this.getReviewDetail()
				} else if(this.role == 'government') {
					this.getGovernmentDetail()
				} else if(this.role == 'staff') {
					this.getStaffDetail()
				} else if(this.role == 'filter') {
					this.getFilterDetail()
				}
			},
			// 文件预览
			showFile(url) {
				this.$refs.show.preview(url)
			},
			// 视频下载
			downloadVideoOrAudio(item) {
				this.$message.success('开始下载，请耐心等待!')
				const videoUrl = this.alOssUrl + item.path
				fetch(videoUrl).then((res) => {
				  res.blob().then((blob) => {
				    const blobUrl = window.URL.createObjectURL(blob);
				    const filename = item.name;
				    const a = document.createElement('a');
				    a.href = blobUrl;
				    a.download = filename;;
				    a.click();
				    window.URL.revokeObjectURL(blobUrl);
				  });
				});
			},
			// 附件下载
			downloadFile(path, name) {
				const a = document.createElement('a')
				a.setAttribute('download',(name+'').trim())
				a.setAttribute('target', '_blank')
				a.setAttribute('href', this.alOssUrl + path)
				a.click()
			},
			getFilterDetail() {
				let id = '?id=' + this.listInfo.id
				this.http.getFilterExpertDetail(id).then((res) => {
					this.loading=false;
					this.competition_course = res.data.data. competition_course
					this.competition_course_audio = res.data.data.competition_course_audio
					this.competition_course_video = res.data.data.competition_course_video
					this.competition_course_author = res.data.data.competition_course_author
					this.competition_course_file = res.data.data.competition_course_file
					this.score=res.data.data.score;
				})
			},
			getStaffDetail() {
				let id = '?id=' + this.listInfo.id
				this.http.getDetail(id).then((res) => {
					this.loading=false;
					this.competition_course = res.data.data. competition_course
					this.competition_course_audio = res.data.data.competition_course_audio
					this.competition_course_video = res.data.data.competition_course_video
					this.competition_course_author = res.data.data.competition_course_author
					this.competition_course_file = res.data.data.competition_course_file
					this.score=res.data.data.score;
				})
			},
			getGovernmentDetail() {
				let id = '?id=' + this.listInfo.id
				this.http.courseDetail(id).then((res) => {
					this.loading=false;
					this.competition_course = res.data.data. competition_course
					this.competition_course_audio = res.data.data.competition_course_audio
					this.competition_course_video = res.data.data.competition_course_video
					this.competition_course_author = res.data.data.competition_course_author
					this.competition_course_file = res.data.data.competition_course_file
					this.score=res.data.data.score;
				})
			},
			getReviewDetail() {
				let id = '?id=' + this.listInfo.id
				this.http.getExpertDetail(id).then((res) => {
					this.loading=false;
					this.competition_course = res.data.data. competition_course
					this.competition_course_audio = res.data.data.competition_course_audio
					this.competition_course_video = res.data.data.competition_course_video
					this.competition_course_author = res.data.data.competition_course_author
					this.competition_course_file = res.data.data.competition_course_file
					this.score=res.data.data.score;
				})
			}
		},
		mounted() {
			
		}
	}
</script>

<style scoped>
	.dialog_Model /deep/ .el-dialog--center .el-dialog__body {
		padding: 20px 20px 30px !important;
		max-height: 70vh !important;
		overflow-y: auto;
	}
	.form-style {
		padding: 0 20px;
	}
	.form-style .el-form-item {
		/* line-height: 21px !important; */
		margin-bottom: 0px;
		color: #333333;
	}
	.description-box {
		margin-top: 10px;
		line-height: 21px;
	}
	.description-box pre {
		font-family: "Microsoft YaHei";
		white-space: pre-wrap;
		word-wrap: break-word;
	}
	.cancel-btn {
		width: 60px;
		height: 33px;
		background: #E8E8E8;
		opacity: 1;
		border: none;
		border-radius: 5px;
		font-size: 14px;
		font-weight: 400;
		line-height: 19px;
		color: #333333;
		opacity: 1;
		cursor: pointer;
	}
	.cancel-btn:hover {
		background: #D1D1D1;
	}
	.comment-default-text-btn {
		color: #5485FC ;
		font-size: 14px;
		cursor: pointer;
	}
	.video-style {
		object-fit:contain;
		width: 355px;
		height: 182px;
		border-radius: 5px;
		margin-right: 20px;
	}
	.audio-style {
		margin-right: 20px;
	}
	.video-form-item:first-child {
		margin-top: 10px;
	}
</style>
